import React from "react"
import {Typography, Button, Grid, Container, Divider, Hidden} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import net from "../images/net_so.svg"
import elipses from "../images/elipses.svg"

const useContainerStyles = makeStyles({
    root: {
        padding: '0 3rem',
        width: '100%',
        boxSizing: 'border-box',
        position: 'relative',
    }
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        color: '#fff'
    }
});

const useButtonStyles = makeStyles({
    root: {
        textTransform: 'none',
        borderRadius: "50px",
        padding: '30px 70px',
        background: 'linear-gradient(180deg, rgba(240,181,147,1) 20%, rgba(236,160,116,1) 100%)',
        '&:hover': {
            textDecoration: 'none',
        }
    }
});

const useButtonSecondaryStyles = makeStyles({
    root: {
        textTransform: 'none',
        borderRadius: "50px",
        padding: '30px 70px',
        borderWidth: '3px',
        '&:hover': {
            textDecoration: 'none',
            borderWidth: '3px'
        }
    }
});

const SectionOne = () => {
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const smallScreen = useMediaQuery("(min-width:600px) and (max-width:959px)");
    const containerClasses = useContainerStyles();
    const textClasses = useTextStyles();
    const buttonClasses = useButtonStyles();
    const buttonSecondaryClasses = useButtonSecondaryStyles();

    return (
        <Container className={containerClasses.root} maxWidth={'xl'}>
            <Grid container justify={'center'} item alignItems={'flex-end'}>
                <Divider style={{width: '90%', backgroundColor: '#25292f'}}/>
            </Grid>
            <Grid item container direction={'column'} md={7} style={(xsScreen || smallScreen) ? null : null}>
                <Grid item container spacing={8}>
                    <Grid item container alignItems={'center'}>
                        <Typography variant={'h3'} color={'secondary'} style={{marginTop: '3rem', width: '100%', hyphens: "auto"}}>
                            Innovative Ecommerce
                        </Typography>
                        <Typography variant={'h3'} color={'secondary'}>
                            Brands in One Place<span style={{color: '#eeac86'}}>.</span>
                        </Typography>
                    </Grid>
                    <Grid item container md={10}>
                        <Typography variant={'h6'} className={textClasses.root}>
                            Our group will help you outpace the competition digitally. <br />
                            From logistics to product sourcing, Eparia and our group of brands help businesses redefine
                            digital sales, capturing new opportunities and growing exponentially.
                        </Typography>
                    </Grid>
                    <Grid item container spacing={4}>
                        <Grid item>
                            <Button href={'/#brands'} className={buttonClasses.root} color={'primary'}
                                    variant={'contained'}>
                                See our brands
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button href={'/#contact'} className={buttonSecondaryClasses.root} color={'secondary'}
                                    variant={'outlined'}>
                                Contact us now
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden smDown={true}>
                <img style={{width: '800px', position: 'absolute', left: '50%', top: '37px', zIndex: 99}} src={net}
                     alt={'background net'}/>
                <img style={{width: '280px', position: 'absolute', left: '63%', top: '270px', zIndex: 98}} src={elipses}
                     alt={'background net'}/>
            </Hidden>
        </Container>
    )
};

export default SectionOne;
