import React from "react"
import {Grid} from "@material-ui/core"
import Layout from "../components/layout"
import SectionOne from '../sections/sectionone'
import SectionTwo from '../sections/sectiontwo'
import SectionThree from "../sections/sectionthree";

const Homepage = (props) => {
  return (
      <Layout>
          <SectionOne/>
          <SectionTwo/>
          <SectionThree/>
      </Layout>
  );
};

export default Homepage
