import React, {useState, useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Typography, Box, Grid, Divider, Button} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';

const useButtonStyles = makeStyles({
    root: {
        textTransform: 'none',
        width: '100%',
        fontSize: '1rem'
    }
});

const useBoxStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '50px',
        padding: '50px 0',
        left: '0px',
        zIndex: 99,
        backgroundColor: '#151920',
        borderRadius: '200px',
        width: '200px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        transition: 'all 0.5s ease'
    }
});

const Sidebar = () => {
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const smallScreen = useMediaQuery("(min-width:600px) and (max-width:959px)");
    const mediumScreen = useMediaQuery("(min-width:960px) and (max-width:1279px)");
    const boxClasses = useBoxStyles();
    const buttonClasses = useButtonStyles();
    let [height, setHeight] = useState(50);
    const dpi = window.devicePixelRatio;

    function isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        );
    }

    function slideOnScroll() {
        let bar = document.querySelector('#gotop');
        let {top} = bar?.getBoundingClientRect();
        let barVisible = isInViewport(bar);

        if (barVisible && window.scrollY >= top) {
            let barScrollTop = window.scrollY - 150 * dpi;
            setHeight(barScrollTop);
        } else if (!barVisible) {
            setTimeout(() => {
                    let barScrollTop = window.scrollY - 150 * dpi;
                    setHeight(barScrollTop);
                },
                200)
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', slideOnScroll, {
            passive: true
        });

        return () => {
            document.removeEventListener('scroll', slideOnScroll)
        }
    }, []);

    return (
        <Box className={boxClasses.root} style={{height: `${height}px`, minHeight: '700px', maxHeight: '4500px'}}>
            <Grid item container justify={'space-between'} alignItems={'center'} direction={'column'} xs={12}
                  style={{height: '100%'}}>
                <Grid container justify={'center'} item spacing={2}>
                    <Grid item xs={8}>
                        <Divider style={{backgroundColor: '#eeac86'}}/>
                    </Grid>
                    <Grid item>
                        <Typography color={'secondary'}>
                            scroll for more
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify={'center'} item spacing={2}>
                    <Grid item>
                        <Button onClick={(e) => {
                            window.scroll(0, 0)
                        }}
                                color={'secondary'}
                                id={'gotop'}
                                className={buttonClasses.root}>
                            go to top
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Divider style={{backgroundColor: '#eeac86'}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};

export default Sidebar;
