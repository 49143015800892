import React, {useState} from 'react';
import {navigate} from "gatsby"
import {Typography, Button, Grid, Container, TextField} from "@material-ui/core"
import styled from 'styled-components'
import {makeStyles, withStyles} from '@material-ui/core/styles';

const useContainerStyles = makeStyles({
    root: {
        padding: '250px 3rem 0 3rem',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
    }
});

const StyledBar = styled('div')`
    width: 100%;
    height: 3px;
    z-index: 100;
    background-color: #eb996a;
    margin-bottom: 3rem;
`;

const useTextStyles = makeStyles({
    root: {
        textAlign: 'center',
        color: '#fff'
    }
});

const FormTextField = withStyles({
    root: {
        '& input + fieldset': {
            color: '#8a8e93',
            borderColor: '#8a8e93',
        },
        '& label': {
            color: '#8a8e93',
        },
        '& textarea': {
            color: '#8a8e93',
            padding: '0 1.25rem'
        },
        '& input:hover': {
            borderColor: '#8a8e93',
            color: '#8a8e93',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #8a8e93',
            borderRadius: '30px',
            color: '#8a8e93',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8a8e93',
        },
        '& input': {
            color: '#8a8e93',
        },
        '& input:-internal-autofill-selected': {
            border: '2px solid #8a8e93 !important',
            borderRadius: '30px !important',
            color: '#8a8e93 !important',
        }
    },
})(TextField);

const useButtonStyles = makeStyles({
    root: {
        textTransform: 'none',
        borderRadius: "50px",
        padding: '15px 70px',
        background: 'linear-gradient(180deg, rgba(240,181,147,1) 20%, rgba(236,160,116,1) 100%)',
        '&:hover': {
            textDecoration: 'none',
        }
    }
});

const SectionThree = () => {
    const containerClasses = useContainerStyles();
    const textClasses = useTextStyles();
    const buttonClasses = useButtonStyles();
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [message, setMessage] = useState('');

    function encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
                "form-name": event.target.getAttribute("name"),
                name,
                email,
                message
            })
        }).then(() => navigate("/thank-you/")).catch(error => alert(error))
    };

    return (
        <Container className={containerClasses.root} maxWidth={'xl'}>
            <Grid container item spacing={4} justify={'center'} id={'contact'}>
                <Grid item container justify={'center'}>
                    <Typography variant={'h4'} color={'secondary'} style={{textAlign: 'center'}}>
                        Write us a message<span style={{color: '#eeac86'}}>.</span>
                    </Typography>
                </Grid>
                <Grid item md={8} container justify={'center'}>
                    <Typography variant={'h6'} className={textClasses.root}>
                        Want to learn more or have questions about our services? <br /> Write us a message below and we’ll be
                        in touch shortly.
                    </Typography>
                </Grid>
                <Grid item container justify={'center'}>
                    <Grid item md={2}>
                        <StyledBar/>
                    </Grid>
                </Grid>
                <form name="contact" method="POST"
                      data-netlify="true"
                      netlify-honeypot="bot-field"
                      onSubmit={handleSubmit}
                      style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Grid item container justify={'center'} spacing={4} md={8}>
                        <Grid md={5} spacing={3} direction={'column'} container item>
                            <Grid item container>
                                <FormTextField
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    variant={'outlined'}
                                    color={"secondary"}
                                    name={'name'}
                                    label={'Name'} required fullWidth/>
                            </Grid>
                            <Grid item container>
                                <FormTextField
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    variant={'outlined'} color={'secondary'}
                                    label={'E-mail adress'} name={'email'} type={'email'} required fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid md={7} spacing={4} container item>
                            <Grid item container>
                                <FormTextField
                                    onChange={e => setMessage(e.target.value)}
                                    value={message}
                                    multiline rows={5} name={'message'} variant={'outlined'} color={'secondary'}
                                    placeholder={'Type message...'} required fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid item container justify={'center'}>
                            <input style={{display: 'none'}} name="bot-field"/>
                            <Button type={'submit'} className={buttonClasses.root} color={'primary'}
                                    variant={'contained'}>Send</Button>
                            <input type="hidden" name="form-name" value="contact"/>
                        </Grid>
                    </Grid>
                </form>
                <Grid item md={8}>
                    <Typography variant={'h5'} color={'secondary'} style={{textAlign: 'center', margin: '2rem 0'}}>
                        Rather send us an email? Write to <a href={'mailto:contact@eparia.com'} style={{
                        color: '#fff',
                        textDecoration: 'none'
                    }}>contact@eparia.com</a>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
};

export default SectionThree;
